#main {
    .container {
        background: #000;
    }
    #left {
        @include phone-and-large-phone {
            margin-bottom: 10px ;
        }
        @include tablet {
            margin-bottom: 20px;
        }
        @include tablet-up {
            @include span(3 of 12);
        }
        @include desktop-up {
            @include span(3 of 12);
        }
    }
    #right {
        @include tablet-up {
            @include span(9 of 12 last);
        }
        @include desktop-up {
            @include span(9 of 12 last);
        }
    }
}
/* promo */
#promo {
    @include clearfix;
    .container {
        padding-bottom: 0;
        @include phone-and-tablet {
            padding: 0;
        }
    }

    .item {
        img {
            width: 100%;
            display: block;
        }
    }
}
/* grid */
.grid {
    @include clearfix;
    .grid-item {
        margin-bottom: 10px;
        @include tablet {
            margin-bottom: 20px;
        }
        @include desktop-up {
            margin-bottom: gutter();
        }
    }
    &.two {
        .grid-item {
            /*@include gallery(6 of 12);*/
            @include phone-up {
                @include gallery(6 of 12);
            }
        }
    }
    &.three {
        .grid-item {
            @include phone-up {
                @include gallery(6 of 12);
            }
            @include tablet-up {
                @include gallery(4 of 12);
            }
        }
    }
    &.four {
        .grid-item {
            @include phone-up {
                @include gallery(6 of 12);
            }
            @include tablet-up {
                @include gallery(4 of 12);
            }
            @include desktop-up {
                @include gallery(3 of 12);
            }
        }
    }
    &.six {
        .grid-item {
            @include phone-up {
                @include gallery(6 of 12);
            }
            @include tablet-up {
                @include gallery(4 of 12);
            }
            @include desktop-up {
                @include gallery(2 of 12);
            }
        }
    }
}