.contact {
    color: #FFF;
    h1 {
        margin-top: 0;
    }
    h2 {
        margin-bottom: 15px;
    }
    p {
        line-height: 1.6em;
        margin: 10px 0;
    }
    .contact-item {
        margin-bottom: 20px;
        h4 {
            margin-bottom: 10px;
        }
        a {
            border-bottom: 2px solid #ff0;
            color: #ff0;
        }
    }
    .iframe-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px; height: 0; overflow: hidden;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}