.product {
    @include clearfix;
    margin-bottom: 20px;
    h1 {
        margin-top: 0;
    }
    .product-left {
        @include desktop-up {
            float: left;
            width: calc(50% - 10px);
        }
    }
    .product-right {
        background: rgb(51,51,51);
        background: -moz-linear-gradient(top,  rgba(51,51,51,1) 1%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(51,51,51,1) 1%,rgba(0,0,0,1) 100%);
        background: linear-gradient(to bottom,  rgba(51,51,51,1) 1%,rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#000000',GradientType=0 );
        @include phone-and-tablet {
            margin-top: 20px;
        }
        padding: 10px;
        @include desktop-up {
            float: right;
            width: calc(50% - 40px);
            padding: 15px;
        }
        font-size: 14px;
        h2 {
            font-size: 16px;
            margin-bottom: 10px;
            color: #ff0;
            border-bottom: 1px solid #ff0;
            padding-bottom: 4px;
        }
        .product-desc {
            @include clearfix;
            margin-bottom: 20px;
            ul {
                @include desktop-up {
                    float: left;
                    width: calc(50% - 10px);
                    &:last-child {
                        float: right;
                    }
                }
                li {
                    display: block;
                    font-size: 12px;
                    color: #a1a1a1;
                    margin-bottom: 2px;
                    span {
                        float: right;
                        color: #e2e2e2;
                    }
                }
            }
        }
        > p {
            font-size: 14px;
            color: #a1a1a1;
        }
        .product-price {
            @include clearfix;
            margin: 20px 0;
            .price {
                font-size: 30px;
                color: #ff0;
                font-weight: 700;
                padding: 5px 10px;
                float: right;
            }
            .product-checkOut {
                padding-top: 10px;
                @include clearfix;
                span {
                    font-size: 20px;
                    color: #e2e2e2;
                }
                .cart {
                    float: left;
                    width: 90px;
                }
                .lower-price {
                    float: right;
                    width: calc(100% - 100px);
                    text-align: right;
                    span {
                        display: block;
                    }
                    div {
                        margin-top: 12px;
                        span, input {
                            display: inline-block;
                        }
                        input[type=text] {
                            padding: 2px;
                            width: 76px;
                            height: 28px;
                            margin-left: 18px;
                        }
                    }
                }
            }
        }
        .social {
            display: flex;
            li {
                flex: 1;
                text-align: center;
            }
        }
    }
}
#gallery-1 {
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.product-options {
    h4 {
        padding: 3px 15px 1px;
        margin-bottom: 10px;
        background: #ff0;
        font-size: 14px;
        text-transform: uppercase;
        color: #000;
    }
    .product-item {
        header {
            margin: 4px 0 8px;
            @include clearfix;
            h3 {
                float: left;
                margin-top: 3px;
                font-size: 14px;
                text-transform: uppercase;
                a {
                    color: #e2e2e2;
                }
            }
            div {
                float: right;
                width: 80px;
                font-size: 14px;
                color: #a1a1a1;
                .text {
                    float: right;
                    margin: 3px 5px 0 0;
                }
                .bg {
                    border: 3px solid red;
                    float: right;
                    @include border-radius(3px);
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    input[type="checkbox"] {
                        width: 18px;
                        height: 16px;
                        cursor: pointer;
                        position: relative;
                        left: -1px;
                    }
                }
            }
        }
        p {
            font-size: 14px;
            color: #a1a1a1;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        footer {
            margin-top: 8px;
            @include clearfix;
            span {
                float: left;
                width: 50%;
                font-size: 16px;
                color: #e2e2e2;
                text-transform: uppercase;
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}
.product-detail {
    h4 {
        padding: 3px 15px 1px;
        margin-bottom: 10px;
        background: #ff0;
        font-size: 14px;
        text-transform: uppercase;
        color: #000;
    }
    .productDetail-text {
        @include clearfix;
        padding: 20px 0;
        background: #FFF;
        p {
            color: #373737;
            font-size: 14px;
            margin-bottom: 15px;
            line-height: 20px;
        }
        img {
            max-width: 100%;
            margin-bottom: 15px;
        }
    }
}
.product-related {
    margin-top: 20px;
    h4 {
        padding: 3px 15px 1px;
        margin-bottom: 10px;
        background: #ff0;
        font-size: 14px;
        text-transform: uppercase;
        color: #000;
    }
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    h5 {
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
        a {
            color: #e2e2e2;
        }
    }
}