.order {
    color: #a1a1a1;
    .order-checkOut {
        a {
            display: inline-block;
            width: 26px;
            height: 26px;
            background: #f4f4f4;
            border: 1px solid #ccc;
            color: #777;
            line-height: 30px;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            vertical-align: top;
        }
        input {
            display: inline-block;
            vertical-align: middle;
            width: 35px;
            font-size: 14px;
            text-align: center;
            border: 1px solid #ccc;
            margin: 0 -1px;
            color: #777;
            height: 26px;
        }
    }
    table td:nth-child(6), table th:nth-child(6) {
        text-align: center;
    }
    .order-options {
        @include clearfix;
    }
    .order-type {
        @include phone-and-tablet {
            padding: 20px;
            text-align: center;
        }
        @include desktop-up {
            float: left;
            width: 40%;
            padding-top: 70px;
        }
        div {
            margin-bottom: 15px;
        }
    }
    .order-price {
        @include desktop-up {
            float: right;
            width: 60%;
        }
        div {
            @include clearfix;
            display: block;
            width: 100%;
            margin-top: 0;
            > span {
                @include phone-and-tablet {
                    width: calc(50% - 10px);
                }
            }
            span.price-title {
                float: left;
                @include desktop-up {
                    width: 60%;
                }
                color: #fff;
                font-size: 16px;
                @include phone-up {
                    font-size: 24px;
                }
                font-weight: 700;
                padding: 9px 0 0;
                text-align: right;
                &.long {
                    font-size: 16px;
                    @include phone-up {
                        font-size: 20px;
                    }
                }
            }
            span.price {
                float: right;
                @include desktop-up {
                    width: calc(40% - 20px);
                }
                color: #ff0;
                font-size: 18px;
                @include phone-up {
                    font-size: 26px;
                }
                font-weight: 700;
                padding: 7px 0;
                text-align: left;
            }
            span.price-promo {
                float: right;
                @include desktop-up {
                    width: calc(40% - 20px);
                }
                margin: 7px 0 40px;
                input[type=text] {
                    width: 100px;
                }
            }
        }
    }
    p.order-desc {
        margin-top: 5px;
        text-align: right;
        font-size: 12px;
        color: #a1a1a1;
    }
    .order-form {
        margin-top: 10px;
        border: 1px solid #ff0;
        font-size: 14px;
        padding: 10px;
        .orderForm-des {
            text-align: center;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
        p {
            @include clearfix;
            margin-bottom: 10px;
            label {
                @include phone {
                    margin-bottom: 5px;
                    display: block;
                }
                @include phone-up {
                    float: left;
                    width: 200px;
                }
            }
            input[type=text], .order-checkbox {
                @include phone {
                    width: 100%;
                }
                @include phone-up {
                    float: left;
                    margin-left: 20px;
                    width: calc(100% - 232px);
                }
            }
            .order-checkbox {
                span:last-child {
                    margin-left: 20px;
                }
                @include phone-up {
                    margin-left: 222px;
                }
            }
            input {
                padding: 5px;
                border: 1px solid #e1e1e1;
            }
            button, .dismiss {
                display: inline-block;
            }
            button {
                margin-top: 20px;
                color: #ff0;
                text-transform: uppercase;
                background: none;
                font-size: 18px;
                font-weight: 700;
                padding: 7px 10px;
                border: none;
            }
            .dismiss {
                margin-left: 20px;
                text-transform: uppercase;
                font-weight: 600;
                a {
                    color: #e2e2e2;
                }
            }
            &:last-child {
                margin-top: 30px;
            }
        }
        .error {
            float: left;
            color: red;
            font-size: 12px;
            margin-top: 2px;
            clear: both;
            @include phone-up {
                margin-left: 222px;
            }
        }
    }
    .order-info {
        padding: 5px;
        margin: 20px 0;
        text-align: center;
        font-weight: 600;
    }
    .post-info {
        display: block;
        margin: 10px 0;
        span {
            display: inline-block;
            text-transform: uppercase;
            padding: 4px 4px 2px;
            font-size: 13px;
            background: #fe0201;
            color: #FFF;
            float: left;
            margin: 0 10px 0 20px;
        }
        p {
            color: #fe0201;
            font-size: 13px;
            position: relative;
            top: 3px;
        }
    }
}