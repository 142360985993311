#top {
    @include phone-and-tablet {
        .container {
            padding-bottom: 0;
        }
    }
    @include desktop-up {
        .top {
            margin-bottom: -6px;
            .container {
                position: relative;
                padding: 8px 20px 0;
            }
        }
    }
    .logo {
        background: url("../img/logo.png") no-repeat;
        width: 358px;
        height: 90px;
        display: inline-block;

        @include hidpi {
            background: url("../img/logo.png") no-repeat;
            background-size: 179px 45px;
            width: 179px;
            height: 45px;
        }
    }
    #menu {
        background: #000;
        display: inline-block;
        float: left;
        width: 100%;
        @include tablet-up {
            padding-bottom: 4px;
        }
        ul {
            @include clearfix;
/*            @include large-phone {
                display: flex;
                justify-content: space-around;
            }*/
            li {
                float: left;
                @include small-phone {
                    margin-right: 10px;
                }
                margin-right: 15px;
                a {
                    color: #FFF;
                    text-transform: uppercase;
                    @include phone-and-large-phone {
                        padding: 9px 5px;
                    }
                    @include small-phone {
                        padding: 9px 2px;
                    }
                    @include tablet-up {
                        position: relative;
                        top: -2px;
                        padding: 11px 7px 2px;
                    }
                    @include desktop-up {
                        padding: 11px 8px 2px;
                    }
                    display: block;
                }
                @include phone-and-large-phone {
                    display: none;
                }
                &:first-child {
                    @include phone-and-large-phone {
                        margin-left: 5px;
                    }
                    img {
                        display: block;
                        @include tablet-up {
                            display: none;
                        }
                    }
                    @include phone-and-large-phone {
                        display: block;
                        a {
                            padding: 0;
                        }
                        span {
                            display: none;
                        }
                    }
                }
                &.featured {
                    @include phone-and-large-phone {
                        display: block;
                    }
                    position: relative;
                    top: -4px;
                    a {
                        position: relative;
                        top: 2px;
                        color: #000;
                        font-weight: bold;
                    }
                    .triangle {
                        width: 0;
                        height: 0;
                        padding-bottom: 4px;
                        padding-left: 10px;
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        right: -10px;
                        &:after {
                            content: "";
                            display: block;
                            width: 0;
                            height: 0;
                            margin-left: -10px;
                            border-top: 4px solid transparent;
                            border-left: 10px solid #666;
                        }
                    }
                    &.yellow {
                        background: #ff0;
                    }
                    &.red {
                        background: #f00;
                    }
                    &.orange {
                        background: #ff8400;
                        @include phone {
                            margin-right: 0;
                        }
                    }
                }
                /* hideing */
                &.hide-tablet {
                    @include tablet-landspace {
                        display: none;
                    }
                }
            }
        }
    }
    .search {
        @include phone-and-large-phone {
            display: none;
        }
        display: inline-block;
        float: left;
        background: #000;
        font-size: 12px;
        margin: 3px 0;
        @include border-radius(2px);
        input {
            border: none;
        }
        input[type=text] {
            padding: 0 10px;
            color: #FFF;
            background: none;
            position: relative;
            top: 1px;
            width: 204px;
        }
        ::-webkit-input-placeholder {
            color: white;
         }

         :-moz-placeholder { /* Firefox 18- */
            color: white;
         }

         ::-moz-placeholder {  /* Firefox 19+ */
            color: white;
         }

         :-ms-input-placeholder {
            color: white;
         }
        input[type=submit] {
            background: #ff0;
            padding: 4.5px 3px;
            @include border-radius(2px);
        }
    }
    .mobile-menu {
        display: inline-block;
        @include desktop-up {
            display: none;
        }
        float: left;
        @include phone-and-large-phone {
            width: calc(100% - 20px);
            padding: 6px 10px 3px;
        }
        ul {
/*            @include phone-and-large-phone {
                display: flex;
                justify-content: space-around;
            }*/
        }
        li {
            @include phone-and-large-phone {
                float: left;
                padding: 7px 25px 10px 0;
                &:last-child {
                    padding-right: 0;
                }
            }
            @include small-phone {
                padding-right: 10px;
            }
            @include tablet-up {
                display: none;
            }
            @include tablet-landspace {
                &.show-tablet {
                    display: block;
                    margin: 7px 0 0 14px;
                }
            }
            a {
                color: #FFF;
                text-transform: uppercase;
                display: block;
                font-size: 14px;
            }
        }
    }
    .cart {
        @include phone-and-large-phone {
            display: none;
        }
        display: inline-block;
        float: right;
        color: #FFF;
        position: relative;
        margin-top: 8px;
        div {
            padding-right: 75px;
            font-size: 12px;
            span {
                color: #ff0;
            }
        }
        .cart-icon {
            @include tablet-up {
                position: absolute;
                bottom: -7px;
                right: 6px;
            }
        }
    }
    .bottom {
        /*background: #343434;*/
        background: rgba(0,0,0,.78);
        .container {
            padding: 0;
        }
    }
}
.choose-language {
    position: absolute;
    top: 10px;
    right: 20px;
    a {
        text-indent: -9999px;
        display: inline-block;
    }
    a.srb {
        @extend .icon-s-serbia;
    }
    a.eng {
        @extend .icon-s-usa;
    }
    span {
        display: block;
    }
}
.mobile-navigation {
    @include tablet-up {
        display: none;
    }
    background: #343434;
    @include clearfix;
    position: relative;
    .search {
        float: left;
        margin: 9px 0 0 84px;
        background: #000;
        font-size: 14px;
        padding: 8px 1px 8px 4px;
        @include border-radius(2px);
        input {
            border: none;
        }
        input[type=text] {
            padding: 0 10px;
            color: #FFF;
            background: none;
            width: calc(100vw - 305px);
        }
        ::-webkit-input-placeholder {
            color: white;
         }

         :-moz-placeholder { /* Firefox 18- */
            color: white;
         }

         ::-moz-placeholder {  /* Firefox 19+ */
            color: white;
         }

         :-ms-input-placeholder {
            color: white;
         }
        input[type=submit] {
            background: #ff0;
            padding: 6px 4px 4px;
            text-transform: uppercase;
            @include border-radius(2px);
            font-weight: bold;
            font-size: 16px;
        }
    }
    .cart {
        float: right;
        width: 140px;
        margin-top: 2px;
        font-size: 12px;
        div {
            float: left;
            width: 55px;
            color: #FFF;
            margin-top: 5px;
            text-align: right;
            span {
                color: #ff0;
            }
        }
        a {
            float: right;
            margin: 0 10px;
        }
    }
}