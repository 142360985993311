* {
    margin: 0;
    padding: 0;
}
body {
    overflow-x: hidden;
}
a {
    text-decoration: none;
}
ul {
    list-style-type: none;
}
figure {
    margin: 0;
}
.clear {
    clear: both;
}
fieldset {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
textarea:focus, input:focus{
    outline: none;
}
.clearfix:after{content:".";display:block;clear:both;visibility:hidden;line-height:0;height:0}.clearfix{display:inline-block}html[xmlns] .clearfix{display:block}* html
.clearfix{height:1%}

// use class when needed
.animation {
    -webkit-transition: all 300ms ease-in;
    -moz-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
}