.page-title {
    background: #333;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #FFF;
    padding: 6px 10px 4px;
    margin-top: 0;
}
.content-page {
    color: #dddcdc;
    h2 {
        background: #333;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        color: #FFF;
        padding: 6px 10px 4px;
        margin: 0 0 15px;
    }
    p {
        line-height: 1.6em;
        margin-bottom: 15px;
        a {
            color: #ff0;
            border-bottom: 2px solid #ff0;
        }
        &.featured {
            background: rgb(47,47,47);
            background: -moz-linear-gradient(top,  rgba(47,47,47,1) 0%, rgba(37,37,37,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(47,47,47,1) 0%,rgba(37,37,37,1) 100%);
            background: linear-gradient(to bottom,  rgba(47,47,47,1) 0%,rgba(37,37,37,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f2f2f', endColorstr='#252525',GradientType=0 );
            font-size: 20px;
            padding: 20px;
        }
        &.gray {
            background: #262626;
            color: #cecece;
            display: block;
            text-align: center;
            font-size: 20px;
        }
    }
    figure {
        display: block;
        text-align: center;
        margin-bottom: 15px;
        img {
            display: inline-block;
            max-width: 100%;
        }
    }
    .content-bg {
        background: #272727;
        padding: 10px;
        margin-bottom: 20px;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.weight-outer, .wide-button {
    display: block;
    text-align: center;
}
.weight, .wide-button a {
    @include border-radius(5px);
    background: rgb(0,0,0);
    background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(48,48,48,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(48,48,48,1) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(48,48,48,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#303030',GradientType=0 );
    padding: 8px 60px 6px;
    margin: 0 5px;
    color: #ff0;
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
}
.wide-button a:hover {
    background: rgb(81,83,0);
    background: -moz-linear-gradient(top,  rgba(81,83,0,1) 0%, rgba(131,129,28,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(81,83,0,1) 0%,rgba(131,129,28,1) 100%);
    background: linear-gradient(to bottom,  rgba(81,83,0,1) 0%,rgba(131,129,28,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#515300', endColorstr='#83811c',GradientType=0 );
}
.line-separator {
    display: block;
    height: 28px;
    background: #292929;
    margin: 10px 0;
}
.parts {
    @include clearfix;
    p {
        float: left;
        width: calc(100% - 170px);
        margin: 8px 0 0;
        i {
            margin-left: 5px;
        }
    }
}
.big-button {
    float: right;
    a {
        width: 160px;
        @include border-radius(5px);
        display: inline-block;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(48,48,48,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(48,48,48,1) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(48,48,48,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#303030',GradientType=0 );
        padding: 9px 0 7px;
        margin: 0 5px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #d0d0d0;
        &:hover {
            background: rgb(81,83,0);
            background: -moz-linear-gradient(top,  rgba(81,83,0,1) 0%, rgba(131,129,28,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(81,83,0,1) 0%,rgba(131,129,28,1) 100%);
            background: linear-gradient(to bottom,  rgba(81,83,0,1) 0%,rgba(131,129,28,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#515300', endColorstr='#83811c',GradientType=0 );
            color: #ff0;
        }
    }
}