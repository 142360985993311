// Susy settings
$susy: (
    columns: 12,
    gutter-position: after,
    gutters: 0.25,
    global-box-sizing: border-box,
    debug: (
        image: hide,
        color: rgba(blue, 0.10)
    ),
    use-custom: (
        box-sizing: false
    )
);

// Defining breakpoints
$bp-extra-small: em(375);
$bp-small: em(480);
$bp-medium: em(768);
$bp-medium-landscape: em(1024);
$bp-large: em(1280);

// Media query helpers - with susy-media
@mixin small-phone {
    @include susy-media(0 ($bp-extra-small - em(1))) { @content }; /* 0 - 349 */
}
@mixin phone {
    @include susy-media(0 ($bp-small - em(1))) { @content }; /* 0 - 479 */
}
@mixin phone-up {
    @include susy-media($bp-small) { @content }; /* 480+ */
}
@mixin large-phone {
    @include susy-media($bp-small ($bp-medium - em(1))) { @content }; /* 480 - 767 */
}
@mixin phone-and-large-phone {
    @include susy-media(0 ($bp-medium - em(1))) { @content }; /* 0 - 767 */
}
@mixin tablet {
    @include susy-media($bp-medium ($bp-large - em(1))) { @content }; /* 768 - 1279 */
}
@mixin tablet-landspace {
    @include susy-media($bp-medium ($bp-medium-landscape - em(1))) { @content }; /* 768 - 1023 */
}
@mixin tablet-up {
    @include susy-media($bp-medium) { @content }; /* 768+ */
}
@mixin tablet-landscape-up {
    @include susy-media($bp-medium-landscape) { @content }; /* 1024+ */
}
@mixin phone-and-tablet {
    @include susy-media(0 ($bp-large - em(1))) { @content }; /* 0 - 1279 */
}
@mixin large-phone-and-tablet {
    @include susy-media($bp-small ($bp-large - em(1))) { @content }; /* 480 - 1279 */
}
@mixin desktop-up {
    @include susy-media($bp-large) { @content }; /* 1280+*/
}

// Defining container widths
.container {
    @include clearfix;
    @include phone-and-large-phone {
        padding: 10px ;
    }
    @include tablet-up {
        padding: 20px;
    }
    @include container(1160px);
}
