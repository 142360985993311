#left {
    @include phone-and-large-phone {
        display: none;
    }
    h3 {
        display: block;
        background: #ffff00;
        color: #000;
        text-transform: uppercase;
        padding: 7px 10px 4px;
        margin-bottom: 16px;
    }
}
#product-menu {
    ul {
        z-index: 10;
        .parent-active ul {

        }
        li {
            margin-bottom: 4px;
            position: relative;
            @include border-radius(2px);
            a {
                color: #FFF;
                text-transform: uppercase;
                display: inline-block;
                width: calc(100% - 20px);
                padding: 12px 10px 7px;
                font-size: 18px;
            }
            span.more {
                position: absolute;
                top: 5px;
                right: 2px;
                padding: 0 5px;
                cursor: pointer;
                color: #666;
                &:after {
                    font-family: 'FontAwesome';
                    content: "\f0d7";
                    font-size: 24px;
                    font-weight: initial;
                }
                @include border-radius(2px);
                &.active:after {
                    content: "\f0d8";
                }
            }
            /* second level navigation */
            ul {
                border: 1px solid #1f1f1f;
                padding: 10px 0;
                @include border-radius(0 0 10px 10px);
                li {
                    margin-bottom: 0;
                    a {
                        color: #ffff00;
                        font-size: 12px;
                        text-transform: none;
                        padding: 14px 14px 10px;
                    }
                }
            }
        }
        ul {
            margin: 4px 0;
            padding: 0;
        }
    }
    > ul > li > ul {
        display: none;
    }
    ul ul ul {
        display: none;
    }
    > ul > li > a {
        background: #171818;
        background: -moz-linear-gradient(top,  #171818 0%, #2d2d2d 100%);
        background: -webkit-linear-gradient(top,  #171818 0%,#2d2d2d 100%);
        background: linear-gradient(to bottom,  #171818 0%,#2d2d2d 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171818', endColorstr='#2d2d2d',GradientType=0 );
    }
}