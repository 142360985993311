#nav-toggle {
    cursor: pointer;
    width: 60px;
    height: 33px;
    padding: 24px 0 0 6px;
    display: block;
    /*float: left;*/
    position: absolute;
    left: 10px;
    top: 4px;
    background: #ff0;
}
#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 50px;
    background: #000;
    position: absolute;
    display: block;
    content: '';
    left: 7px;
}
#nav-toggle span:before {
    top: -13px;
    left: 0;
}
#nav-toggle span:after {
    bottom: -13px;
    left: 0;
}

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
    transition: all 500ms ease-in-out;
}
#nav-toggle.active span {
    background-color: transparent;
}
#nav-toggle.active span:before, #nav-toggle.active span:after {
    top: 0;
}
#nav-toggle.active span:before {
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}
#nav-toggle.active span:after {
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
}