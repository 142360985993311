.product-list {
    .product-item {
        background: #FFF;
        margin-right: 3px;
        margin-bottom: 4px;
        .product-title {
            padding: 10px;
            background: #171818;
            background: -moz-linear-gradient(top,  #171818 0%, #2d2d2d 100%);
            background: -webkit-linear-gradient(top,  #171818 0%,#2d2d2d 100%);
            background: linear-gradient(to bottom,  #171818 0%,#2d2d2d 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171818', endColorstr='#2d2d2d',GradientType=0 );
            color: #FFF;
            font-size: 11px;
            min-height: 34px;
            a {
                font-size: 14px;
                font-weight: 400;
                margin-top: 4px;
                display: block;
                color: #fff;
            }
        }
        .img {
            text-align: center;
            display: block;
            position: relative;
            img {
                display: inline-block;
                max-width: 100%;
            }
            .promo {
                position: absolute;
                top: 0;
                right: 0;
                &.akcija {
                    right: auto;
                    left: 0;
                }
            }
        }
        .text {
            padding: 5px 10px;
            margin-bottom: -30px;
        }
        .info {
            position: relative;
            bottom: -5px;
            right: -3px;
            background: url("../img/product-bg.png") no-repeat bottom right;
            height: 78px;
            @include clearfix;
            .price {
                position: absolute;
                left: 5px;
                bottom: 14px;
                width: calc(100% - 140px);
                font-weight: bold;
                span {
                    display: block;
                }
            }
            .cart {
                font-size: 12px;
                text-transform: uppercase;
                position: absolute;
                bottom: 5px;
                right: 80px;
                img {
                    width: 30px;
                    position: relative;
                    right: -27px;
                }
                a {
                    display: block;
                    color: #fff;
                }
            }
            .more {
                font-size: 12px;
                text-transform: uppercase;
                position: absolute;
                bottom: 5px;
                right: 4px;
            }
        }
    }
    &.types {
        .product-item {
            @include border-radius(5px);
            @include clearfix;
            background: rgb(40,40,40);
            background: -moz-linear-gradient(top,  rgba(40,40,40,1) 0%, rgba(58,58,58,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(40,40,40,1) 0%,rgba(58,58,58,1) 100%);
            background: linear-gradient(to bottom,  rgba(40,40,40,1) 0%,rgba(58,58,58,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#282828', endColorstr='#3a3a3a',GradientType=0 );
            padding: 10px;
            margin: 0;
            .text {
                margin: 0;
                text-align: center;
                padding: 10px 0 0 0;
                h3 {
                   font-size: 20px;
                   font-weight: bold;
                   text-transform: uppercase;
                   a {
                       color: #b8b8b8;
                   }
                }
            }
        }
    }
}
.select-list {
    font-size: 14px;
    margin: 0;
    .list-title {
        padding: 10px 15px 8px;
        background: #2d2d2d;
        margin: 0 0 20px 0;
        h4 {
            font-size: 18px;
            color: #ccc;
            background: #2d2d2d;
            border-bottom: 2px solid #ccc;
        }
        &.no-bg {
            background: none;
            h4 {
                background: none;
            }
        }
    }
    form {
        @include clearfix;
        div {
            font-size: 18px;
            margin-bottom: 10px;
            @include phone-and-large-phone {
                @include gallery(3 of 6);
                &:last-child {
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    clear: both;
                }
            }
            @include tablet-up {
                display: inline-block;
                margin-right: 1px;
                &.align-right {
                    float: right;
                }
            }
            label {
                display: block;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 10px;
                color: #CCC;
                font-size: 12px;
                font-weight: bold;
                margin-right: 2px;
            }
            select {
                @include phone-and-large-phone {
                    width: 100%;
                }
                min-width: 100px;
                background: #161616;
                background: -moz-linear-gradient(top,  #161616 0%, #2d2d2d 100%);
                background: -webkit-linear-gradient(top,  #161616 0%,#2d2d2d 100%);
                background: linear-gradient(to bottom,  #161616 0%,#2d2d2d 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#161616', endColorstr='#2d2d2d',GradientType=0 );
                padding: 5px 2px 2px;
                border: none;
                color: #c5c5c5;
                text-align: center;
            }
            option {
                text-align: center;
                background: #000;
                color: #FFF;
            }
        }
        input[type=submit] {
            @include border-radius(5px);
            margin-left: 5px;
            background: #e10019;
            background: -moz-linear-gradient(top,  #e10019 0%, #900001 100%);
            background: -webkit-linear-gradient(top,  #e10019 0%,#900001 100%);
            background: linear-gradient(to bottom,  #e10019 0%,#900001 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e10019', endColorstr='#900001',GradientType=0 );
            color: #FFF;
            border: none;
            font-weight: bold;
            @include phone-and-large-phone {
                padding: 5px 40px;
                margin-top: 10px;
            }
            @include tablet-up {
                padding: 6px 52px 3px;
            }
            &:hover {
                background: #900001;
            }
        }
    }
    &.long-title {
        form div label {
            font-size: 12px;
            color: #ccc;
        }
    }
}
// ff fix
@-moz-document url-prefix() {}
.select-list form div select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    background: url("../img/arrow-down.png") no-repeat center right, -moz-linear-gradient(top,  #161616 0%, #2d2d2d 100%);
    padding-right: 15px;
}