// Theme font
@font-face {
    font-family: 'Myriad';
    src: url('../fonts/Myriad Web Pro.eot');  /* IE9 Compat Modes */
    src: url('../fonts/Myriad Web Pro.eot?#iefix') format('embedded-opentype'),  /* IE6-IE8 */
    url('../fonts/Myriad Web Pro.woff') format('woff'),  /* Pretty Modern Browsers */
    url('../fonts/Myriad Web Pro.ttf')  format('truetype') /* Safari, Android, iOS */
}

body {
    font-family: 'Myriad', sans-serif;
    background: url("../img/bg.jpg") no-repeat top center #000;
    color: #373737;
}
a {
    color: #000;
}
a:hover {
    color: #000;
}
h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }