#bottom {
    background: #333;
    color: #676767;
    text-align: center;
    font-size: 14px;
    a {
        color: #878787;
    }
    .social {
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 5px solid #ff0;
        li {
            display: inline-block;
            margin: 0 20px;
        }
    }
    nav {
        display: block;
        ul {
            @include large-phone {
                display: flex;
                justify-content: space-around;
            }
        }
        li {
            font-size: 12px;
            @include phone {
                margin: 0 5px 5px;
            }
            @include tablet-up {
                margin-right: 15px;
            }
            @include desktop-up {
                margin-right: 25px;
            }
            display: inline-block;
            text-transform: uppercase;
        }
    }
    p {
        a {
            color: #8b8b8b;
        }
    }
    .copyright {
        margin: 20px 0 0px;
        @include tablet-up {
            margin-top: 52px;
        }
        float: left;
        display: block;
        width: 100%;
        text-align: center;
        p {
            display: inline-block;
        }
        .line {
            height: 5px;
            background: #ff0;
            margin: 0 20px 10px;
            display: block;
        }
    }
}