.home-list {
    .grid-item {
        position: relative;
        height: 166px;
        background: #171818;
        background: -moz-linear-gradient(top,  #181818 0%, #2d2d2d 100%);
        background: -webkit-linear-gradient(top,  #181818 0%,#2d2d2d 100%);
        background: linear-gradient(to bottom,  #181818 0%,#2d2d2d 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#181818', endColorstr='#2d2d2d',GradientType=0 );
        .text {
            position: absolute;
            @include phone-and-tablet {
                top: 10px;
                left: 10px;
            }
            @include desktop-up {
                top: 20px;
                left: 20px;
            }
            z-index: 1;
            @include desktop-up {
                width: 200px;
            }
            h2 {
                text-transform: uppercase;
                font-size: 29px;
                font-weight: normal;
                a {
                    color: #FFF;
                }
            }
            span {
                margin-top: 10px;
                display: block;
                font-size: 16px;
                @include tablet-up {
                    font-size: 14px;
                    line-height: 16px;
                }
                a {
                    color: #ff0;
                }
            }
        }
        .img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            @include phone-and-tablet {
                text-align: center;
            }
            @include desktop-up {
                text-align: right;
            }
            img {
                display: inline-block;
                z-index: -1;
                max-width: 100%;
                @include phone-and-tablet {
                    opacity: .5;
                    margin: 0 auto;
                }
            }
        }
        &:hover {
            background: rgba(255, 255, 0, .8);
            @include desktop-up {
                background: #ff0;
            }
            h2 a, span a {
                color: #000;
            }
        }
    }
}
.from-social {
    clear: both;
    h4 {
        background: #ff0;
        font-size: 20px;
        padding: 4px 10px 0px;
        color: #000;
        text-transform: uppercase;
    }
}