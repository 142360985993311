/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-s-serbia {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 30px;
  height: 21px;
}
.icon-s-usa {
  background-image: url(../img/sprite.png);
  background-position: -30px 0px;
  width: 30px;
  height: 21px;
}
