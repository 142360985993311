/* 
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-controls {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-controls .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 15px);
    width: 100%;
}
.owl-theme .owl-controls .owl-nav [class*='owl-'] {
  color: #000;
  font-size: 14px;
  margin: 0;
  padding: 15px;
  background: #ff0;
  display: inline-block;
  cursor: pointer;
}
.owl-theme .owl-controls .owl-nav [class*='owl-']:hover {
  background: #000;
  color: white;
  text-decoration: none;
}
.owl-theme .owl-controls .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-controls .owl-nav .owl-prev {
    @include border-radius(0 3px 3px 0);
    float: left;
}
.owl-theme .owl-controls .owl-nav .owl-next {
    @include border-radius(3px 0 0 3px);
    float: right;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  @include desktop-up {
    width: 15px;
    height: 15px;
  }
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #a94d91;
}

/* stop from flashing on load */
/*#slider > div {
    display: none;
}*/
#slider .owl-stage-outer {
    display: block;
}

/* destroy fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
-webkit-backface-visibility: hidden;
-moz-backface-visibility:    hidden;
-ms-backface-visibility:     hidden;
-webkit-transform: translate3d(0,0,0);
-moz-transform: translate3d(0,0,0);
-ms-transform: translate3d(0,0,0);
}
