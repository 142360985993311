.table-view {
    margin-top: 25px;
    .table-item {
        @include clearfix;
        margin-bottom: 25px;
        .table-left {
            @include phone-and-large-phone {
                margin-bottom: 10px;
                display: block;
                text-align: center;
            }
            @include tablet-up {
                @include span(1.25 of 6);
                margin-top: 29px;
            }
            img {
                @include phone-and-large-phone {
                    display: inline-block;
                    width: auto;
                    max-width: 300px;
                }
                @include tablet-up {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
            &.text {
                background: rgb(39,39,39);
                background: -moz-linear-gradient(top,  rgba(39,39,39,1) 0%, rgba(41,41,41,1) 99%);
                background: -webkit-linear-gradient(top,  rgba(39,39,39,1) 0%,rgba(41,41,41,1) 99%);
                background: linear-gradient(to bottom,  rgba(39,39,39,1) 0%,rgba(41,41,41,1) 99%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#272727', endColorstr='#292929',GradientType=0 );
                color: #b9b9b9;
                padding-bottom: 10px;
                margin-top: 0;
                p {
                    font-size: 18px;
                    line-height: 22px;
                    padding: 10px;
                }
            }
        }
        .table-right {
            @include tablet-up {
                @include span(4.75 of 6 last);
            }
        }
    }
}
/* table general */
table {
    width: 100%;
    border: 0;
    font-size: 14px;
    th, td {
        border: 2px solid #000;
        text-align: center;
    }
    tr:nth-child(2n) {
        td {
            background: #191919;
            &:first-child {
                background: none;
            }
        }
    }
    th {
        padding: 5px;
        background: #000;
        color: #FFF;
        font-weight: 400;
        &.post {
            color: #ff0;
        }
    }
    td {
        padding: 1px 5px;
        background: #333;
        color: #FFF;
        a {
            color: #fff;
            &:hover {
                color: #666;
            }
        }
        &.img {
            text-align: center;
            img {
                position: relative;
                top: 2px;
            }
        }
        &:first-child {
            background: none;
            text-align: right;
            img {
                position: relative;
                top: 2px;
            }
        }
        &:nth-child(2) {
            text-align: left;
        }
        &.no-bg {
            background: none !important;
        }
        &.post-type {
            span {
                display: inline-block;
                text-transform: uppercase;
                padding: 4px 4px 2px;
                font-size: 13px;
            }
            &.yes {
                span {
                    background: #3d9c00;
                }
            }
            &.no {
                span {
                    background: #fe0201;
                }
            }
        }
    }
}
.first-inLine {
    background: #e6e604;
    color: #4a4a4a;
    text-transform: uppercase;
    padding: 4px 5px 1px 5px;
    margin-bottom: 15px;
    display: block;
    @include tablet-up {
        margin-left: 30px;
    }
}
.next-inLine {
    background: #a10006;
    color: #FFF;
    text-transform: uppercase;
    padding: 4px 5px 1px 5px;
    margin: 15px 0;
    display: block;
    @include tablet-up {
        margin-left: 30px;
    }
}
.yellow-title {
    background: #e6e604;
    color: #4a4a4a;
    text-transform: uppercase;
    padding: 4px 5px 1px 5px;
    margin-top: 15px;
    display: block;
}