$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

/* mixin for multiline spacing */
/*@function leftSpacing($spacing: 20px) {
    margin-left: $spacing;
}*/